<template>
<div id="bg">
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="用户" name="first">
      <div class="search">
        <div class="search-box">
          <span class="search-box-title">一级渠道：</span>
          <el-select v-model="user.search.channel" @change="user.search.sub_channel=null" filterable placeholder="" size="medium" clearable>
            <el-option label="全部" :value=-1 >全部</el-option>
            <el-option label="默认" :value=0 >默认</el-option>
            <el-option v-for="index in channels.filter((row)=>{
              return row.pid == 0
            })" :key="index.value" :label="index.label" :value="index.value"></el-option>
          </el-select>
        </div>
        <div class="search-box">
           <span class="search-box-title">二级渠道：</span>
          <el-select v-model="user.search.sub_channel" filterable placeholder="" size="medium" clearable>
            <el-option v-for="index in channels.filter((row)=>{
              return row.pid == user.search.channel && row.pid != 0
            })" :key="index.value" :label="index.label" :value="index.value"></el-option>
          </el-select>
        </div>
        <div class="search-box">
          <span class="search-box-title">时间：</span>
          <el-date-picker v-model="user.search.searchDate" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" value-format="YYYY-MM-DD"></el-date-picker>
        </div>
        <div class="search-box">
          <el-button type="primary" size="medium" @click="getChannelUserStatic()">查询</el-button>
        </div>
      </div>
      <el-table :data="user.tableData" :header-cell-style="{ background: '#F7F8FA' }" :row-class-name="rowClass">
        <el-table-column prop="channel" label="渠道" align="center">
          <template #default="scope">
            <span>{{getChannel(scope.row.channel)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="upper_channel" label="上级" align="center">
          <template #default="scope">
            <span v-if="scope.row.upper_channel > 0">[{{scope.row.upper_channel}}]{{scope.row.upper_channel_name}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="day" width="90px" label="日期" align="center" ></el-table-column>
        <el-table-column label="新增人数" align="center">
          <el-table-column prop="reg_all" label="总人数" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="reg_android" label="安卓" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="reg_ios" label="IOS" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="reg_liebian" label="裂变" align="center" :show-overflow-tooltip="true"></el-table-column>
        </el-table-column>
        <el-table-column prop="login_num" label="启动人数" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="reg_num_ago" label="注册量" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            {{scope.row.reg_num1 + scope.row.reg_num_ago}}
          </template>
        </el-table-column>
        <el-table-column prop="reg_num1" label="注册量(当日)" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="留存(存量/%)" align="center">
          <el-table-column prop="day1" label="次日" align="center" width="120" >
            <template #default="scope">
              <span >{{scope.row.day1}}/</span>
              <span v-if="scope.row.reg_all > 0">{{(scope.row.day1 / scope.row.reg_all * 100).toFixed(2)}}%</span>
              <span v-else>0</span>
            </template>
          </el-table-column>
          <el-table-column prop="day3" label="3日" align="center" width="120" >
            <template #default="scope">
              <span >{{scope.row.day3}}/</span>
              <span v-if="scope.row.reg_all > 0">{{(scope.row.day3 / scope.row.reg_all * 100).toFixed(2)}}%</span>
              <span v-else>0</span>
            </template>
          </el-table-column>
          <el-table-column prop="day7" label="7日" align="center" width="120" >
            <template #default="scope">
              <span >{{scope.row.day7}}/</span>
              <span v-if="scope.row.reg_all > 0">{{(scope.row.day7 / scope.row.reg_all * 100).toFixed(2)}}%</span>
              <span v-else>0</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="充值人数" align="center">
          <el-table-column prop="ch_vip_pay" label="通道会员" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="ch_wallet_pay" label="通道钱包" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="ys_vip_pay" label="银商会员" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="ys_wallet_pay" label="银商钱包" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="pay_all" label="总充值" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="pay_suc" label="成功" align="center" :show-overflow-tooltip="true"></el-table-column>
        </el-table-column>
        <el-table-column prop="pay_rate" label="付费率(%)" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span v-if="scope.row.pay_all > 0">{{(scope.row.pay_suc / scope.row.pay_all * 100).toFixed(2)}} %</span>
            <span v-else>{{scope.row.pay_suc * 100}}%</span>
          </template>
        </el-table-column>
<!--        <el-table-column prop="game_num" label="新增游戏账号数" align="center" :show-overflow-tooltip="true"></el-table-column>-->
        <el-table-column prop="watch_count_num" label="观影人数" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="watch_count_num_new" label="新用户观影人数" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="game_sf" label="上分人数" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="game_xf" label="下分人数" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="updatetime" label="更新时间" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span>{{timeToDate(scope.row.updatetime)}}</span>
          </template>
        </el-table-column>
      </el-table>
      <page :pager="user.pager" @query="getChannelUserStatic()" />
    </el-tab-pane>
    <el-tab-pane label="商品" name="second">
      <div class="search">
        <div class="search-box">
          <span class="search-box-title">一级渠道：</span>
          <el-select v-model="user.search.channel" filterable @change="user.search.sub_channel=null" placeholder="" size="medium" clearable>
            <el-option label="全部" :value=-1 >全部</el-option>
            <el-option label="默认" :value=0 >默认</el-option>
            <el-option v-for="index in channels.filter((row)=>{
              return row.pid == 0
            })" :key="index.value" :label="index.label" :value="index.value"></el-option>
          </el-select>
        </div>
        <div class="search-box">
           <span class="search-box-title">二级渠道：</span>
          <el-select v-model="user.search.sub_channel" filterable placeholder="" size="medium" clearable>
            <el-option v-for="index in channels.filter((row)=>{
              return row.pid == user.search.channel && row.pid != 0
            })" :key="index.value" :label="index.label" :value="index.value"></el-option>
          </el-select>
        </div>
        <div class="search-box">
          <span class="search-box-title">时间：</span>
          <el-date-picker v-model="goods.search.searchDate" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" value-format="YYYY-MM-DD"></el-date-picker>
        </div>
        <div class="search-box">
          <el-button type="primary" size="medium" @click="getChannelGoodsStatic()">查询</el-button>
        </div>
      </div>
      <el-table :data="goods.tableData" :header-cell-style="{ background: '#F7F8FA' }">
        <el-table-column prop="channel" label="渠道" align="center">
          <template #default="scope">
            <span>{{getChannel(scope.row.channel)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="upper_channel" label="上级" align="center">
          <template #default="scope">
            <span v-if="scope.row.upper_channel > 0">[{{scope.row.upper_channel}}]{{scope.row.upper_channel_name}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="day" label="日期" align="center" ></el-table-column>
        <el-table-column prop="sell_price_1" :label="vip_title[1].title" v-if="vip_title[1].show" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="sell_num_1" :label="vip_title[1].title_num" v-if="vip_title[1].show" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="sell_price_2" :label="vip_title[2].title" v-if="vip_title[2].show" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="sell_num_2" :label="vip_title[2].title_num" v-if="vip_title[2].show" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="sell_price_3" :label="vip_title[3].title" v-if="vip_title[3].show" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="sell_num_3" :label="vip_title[3].title_num" v-if="vip_title[3].show" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="sell_price_4" :label="vip_title[4].title" v-if="vip_title[4].show" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="sell_num_4" :label="vip_title[4].title_num" v-if="vip_title[4].show" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="sell_price_5" :label="vip_title[5].title" v-if="vip_title[5].show" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="sell_num_5" :label="vip_title[5].title_num" v-if="vip_title[5].show" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="updatetime" label="更新时间" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span>{{timeToDate(scope.row.updatetime)}}</span>
          </template>
        </el-table-column>
      </el-table>
      <page :pager="goods.pager" @query="getChannelGoodsStatic()" />
    </el-tab-pane>
    <el-tab-pane label="金流" name="third">
      <div class="search">
        <div class="search-box">
          <span class="search-box-title">一级渠道：</span>
          <el-select v-model="user.search.channel" filterable @change="user.search.sub_channel=null" placeholder="" size="medium" clearable>
            <el-option label="全部" :value=-1 >全部</el-option>
            <el-option label="默认" :value=0 >默认</el-option>
            <el-option v-for="index in channels.filter((row)=>{
              return row.pid == 0
            })" :key="index.value" :label="index.label" :value="index.value"></el-option>
          </el-select>
        </div>
        <div class="search-box">
           <span class="search-box-title">二级渠道：</span>
          <el-select v-model="user.search.sub_channel" filterable placeholder="" size="medium" clearable>
            <el-option v-for="index in channels.filter((row)=>{
              return row.pid == user.search.channel && row.pid != 0
            })" :key="index.value" :label="index.label" :value="index.value"></el-option>
          </el-select>
        </div>
        <div class="search-box">
          <span class="search-box-title">时间：</span>
          <el-date-picker v-model="price.search.searchDate" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" value-format="YYYY-MM-DD"></el-date-picker>
        </div>
        <div class="search-box">
          <el-button type="primary" size="medium" @click="getChannelPriceStatic()">查询</el-button>
        </div>
      </div>
      <el-table :data="price.tableData" :header-cell-style="{ background: '#F7F8FA' }">
        <el-table-column prop="channel" label="渠道" align="center" >
          <template #default="scope">
            <span>{{getChannel(scope.row.channel)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="upper_channel" label="上级" align="center">
          <template #default="scope">
            <span v-if="scope.row.upper_channel > 0">[{{scope.row.upper_channel}}]{{scope.row.upper_channel_name}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="day" label="日期" align="center"></el-table-column>
        <el-table-column prop="pay_price" label="总营收" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span>{{scope.row.pay_price - scope.row.tk_price}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="pay_price" label="总充值" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="tk_price" label="总提款" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="vip_price" label="VIP充值" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="wallet_price" label="钱包充值" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="av_price" label="视频收益" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="daili_price" label="代理分红" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="sfen_price" label="上分" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="xfen_price" label="下分" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="tips_price" label="社区打赏" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="updatetime" label="更新时间" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span>{{timeToDate(scope.row.updatetime)}}</span>
          </template>
        </el-table-column>
      </el-table>
      <page :pager="price.pager" @query="getChannelPriceStatic()" />
    </el-tab-pane>
  </el-tabs>
</div>
</template>

<script>
import httpClient from "@/config/httpClient";
import page from "@/components/page";
export default {
  name: "channelStatic",
  components: {
    page,
  },
  data() {
    return {
      activeName: 'first',
      channel:-1,
      user:{
        search:{
          sub_channel: [], //状态
          channel: -1, //状态
          child:false,
          searchDate: [], //时间
        },
        tableData:[],
        pager: { total: 0, page: 1, rows: 10 },
        last:null,
      },
      goods:{
        search:{
          channel: -1, //状态
          searchDate: [], //时间
        },
        tableData:[],
        pager: { total: 0, page: 1, rows: 10 },
      },
      price:{
        search:{
          channel: -1, //状态
          searchDate: [], //时间
        },
        tableData:[],
        pager: { total: 0, page: 1, rows: 10 },
        last:null,
      },
      channels: [],
      vip_title:{
        1:{title:"月卡购买额",title_num:"月卡购买人数",show:false,},
        2:{title:"季卡购买额",title_num:"季卡购买人数",show:false,},
        3:{title:"年卡购买额",title_num:"年卡购买人数",show:false,},
        4:{title:"SVIP购买额",title_num:"SVIP购买人数",show:false,},
        5:{title:"至尊卡购买额",title_num:"至尊卡购买人数",show:false,},
      }
    };
  },
  mounted() {
    this.getChannels()
    this.getChannelUserStatic()
  },
  methods: {
    // rowClass({row,column,rowIndex,columnIndex}){
    rowClass(event){
      if (event.row.channel == "汇总"){
        return "fixed-row"
      }
    },
    getVipTitle(){
      return this.vip_title
    },
    timeToDate(time){
      if (time == 0){
        return "-"
      }
      return this.$common.timeToDate(time)
    },
    getChannelUserStatic(){
      this.channel = this.user.search.channel
      this.$common.showLoading("加载中...")
      httpClient("channelUserStatic").post({
        channel:this.user.search.channel,
        bdate:this.user.search.searchDate && this.user.search.searchDate.length > 0?this.user.search.searchDate[0]:"",
        edate:this.user.search.searchDate && this.user.search.searchDate.length > 0?this.user.search.searchDate[1]:"",
        pageid:this.user.pager.page,
        child:this.user.search.sub_channel,
        pcount:this.user.pager.rows,
      }).then((res)=>{
        this.$common.hideLoading()
        if (res.code == 0){
          this.user.tableData = res.data.list
          if (this.user.pager.page == 1){
            this.user.pager.total = res.data.total
            this.user.last = {
              channel:"汇总",
              day:"",
              game_sf:res.data.game_sf,
              game_xf:res.data.game_xf,
              pay_all:res.data.pay_all,
              pay_suc:res.data.pay_suc,
              reg_all:res.data.reg_all,
              reg_num_ago:res.data.reg_num_ago,
              watch_count_num_new:res.data.watch_count_num_new,
              watch_count_num:res.data.watch_count_num,
              reg_android:res.data.reg_android,
              reg_ios:res.data.reg_ios,
              reg_liebian:res.data.reg_liebian,
              day1:res.data.day1,
              day3:res.data.day3,
              day7:res.data.day7,
              reg_num1:res.data.reg1,
              reg_num3:res.data.reg3,
              reg_num7:res.data.reg7,
              login_num:res.data.login_num,
              ys_vip_pay:res.data.ys_vip_pay,
              ys_wallet_pay:res.data.ys_wallet_pay,
              ch_vip_pay:res.data.ch_vip_pay,
              ch_wallet_pay:res.data.ch_wallet_pay,
              updatetime:0,
            }
          }
          if (this.user.last  && this.user.tableData.length > 0 ){
            this.user.tableData.push(this.user.last)
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    getChannelGoodsStatic(){
      this.channel = this.user.search.channel
      this.$common.showLoading("加载中...")
      httpClient("channelGoodsStatic").post({
        // channel:this.goods.search.channel,
        channel:this.user.search.channel,
        bdate:this.goods.search.searchDate && this.goods.search.searchDate.length > 0?this.goods.search.searchDate[0]:"",
        edate:this.goods.search.searchDate && this.goods.search.searchDate.length > 0?this.goods.search.searchDate[1]:"",
        pageid:this.goods.pager.page,
        pcount:this.goods.pager.rows,
        child:this.user.search.sub_channel,
      }).then((res)=>{
        this.$common.hideLoading()
        if (res.code == 0){
          for(const vip of res.data.vip_info){
            if (this.vip_title[vip.id]){
              this.vip_title[vip.id].show = true
              this.vip_title[vip.id].title = vip.title+"消费额"
              this.vip_title[vip.id].title_num = vip.title+"购买人数"
            }
          }
          // for(var i in res.data.list){
          //   for(var j = 1;j<=5;j++){
          //     res.data.list[i]["sell_price_"+j] = 0
          //     res.data.list[i]["sell_num_"+j] = 0
          //   }
          //   var vipinfo = JSON.parse(res.data.list[i].vip_info)
          //   for(var id in vipinfo){
          //     res.data.list[i]["sell_price_"+id] = vipinfo[id]["price"]
          //     res.data.list[i]["sell_num_"+id] = vipinfo[id]["nums"]
          //   }
          // }
          this.goods.tableData = res.data.list
          if (this.goods.pager.page == 1){
            this.goods.pager.total = res.data.total
            this.goods.last = {
              channel:"汇总",
              day:"",
              sell_price_1 : res.data.sell_price_1,
              sell_num_1 : res.data.sell_num_1,
              sell_price_2 : res.data.sell_price_2,
              sell_num_2 : res.data.sell_num_2,
              sell_price_3 : res.data.sell_price_3,
              sell_num_3 : res.data.sell_num_3,
              sell_price_4 : res.data.sell_price_4,
              sell_num_4 : res.data.sell_num_4,
              sell_price_5 : res.data.sell_price_5,
              sell_num_5 : res.data.sell_num_5,
              sell_price_6 : res.data.sell_price_6,
              sell_num_6 : res.data.sell_num_6,
              updatetime:0,
            }
          }
          if (this.goods.last){
            this.goods.tableData.push(this.goods.last)
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    getChannelPriceStatic(){
      this.channel = this.user.search.channel
      this.$common.showLoading("加载中...")
      httpClient("channelPriceStatic").post({
        // channel:this.price.search.channel,
        channel:this.user.search.channel,
        bdate:this.price.search.searchDate && this.price.search.searchDate.length > 0?this.price.search.searchDate[0]:"",
        edate:this.price.search.searchDate && this.price.search.searchDate.length > 0?this.price.search.searchDate[1]:"",
        pageid:this.price.pager.page,
        pcount:this.price.pager.rows,
        child:this.user.search.sub_channel,
      }).then((res)=>{
        this.$common.hideLoading()
        if (res.code == 0){
          this.price.tableData = res.data.list
          if (this.price.pager.page == 1){
            this.price.pager.total = res.data.total
            this.price.last = {
              channel:"汇总",
              day:"",
              pay_price: res.data.pay_price,
              tk_price:res.data.tk_price,
              vip_price:res.data.vip_price,
              wallet_price:res.data.wallet_price,
              av_price:res.data.av_price,
              daili_price:res.data.daili_price,
              sfen_price:res.data.sfen_price,
              xfen_price:res.data.xfen_price,
              tips_price:res.data.tips_price,
              updatetime:0,
            }
          }
          if(this.price.last && this.price.tableData.length > 0){
            this.price.tableData.push(this.price.last)
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    handleClick(tab, event) {
      console.log(tab, event);
      if (this.activeName == "first"){
        this.getChannelUserStatic()
      }else if (this.activeName == "second"){
        this.getChannelGoodsStatic()
      }else{
        this.getChannelPriceStatic()
      }
    },
    getChannel(id){
      if (id == "汇总"){
        return id
      }
      if (this.channel == -1 || this.channel == ""){
        return "全部"
      }
      for(var i in this.channels){
        if (this.channels[i].value == id){
          return "["+id+"]"+this.channels[i].label
        }
      }
      if (id == 0){
        return "["+id+"]"+"默认"
      }
      return "["+id+"]"
    },
    getChannels(){
      httpClient("getChannel").post({ }).then((res)=>{
        if (res.code == 0){
          this.channels = res.data
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    }
  }
}
</script>

<style scoped>
.fixed-row{
  display: inline-block;
  position: sticky;
  bottom: 0;
  width: 100%;
}
.fixed-row  td{
  border: 1px solid #ffffff;
  box-shadow: 2px -2px 3px 0 #ddd;
}
</style>